import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Row } from '@bootstrap-styled/v4';
import BuildYourCXInfo from '../buildyourCX-info/buildyourCX-info';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';

const Step8 = ({ headerMenus, onClickAction, sliderRef, formSubmitted }) => {
    const [submitting, setSubmitting] = useState(false);
    const handleFinalAction = (state) => {
        if (state === 'submit' && !formSubmitted) {
            setSubmitting(true);
        } else {
            setSubmitting(false);
        }
        onClickAction(state);
    };
    return (
        <Wrapper>
            <BuildYourCXInfo
                heading={
                    <Fragment>
                        ibex is our <br />
                        thinking partner
                    </Fragment>
                }
                bigheading={true}
                branddetails={
                    <Fragment>
                        <span>VP of Customer Experience</span>
                        <br />
                        Leading Financial services Brand
                    </Fragment>
                }
            />
            <SubHeading content="All done! Easy, right? Please take a final look at your selections, and select Submit when ready." />
            <Row>
                <ScrollWrapper>
                    <CenterAligned>
                        {headerMenus &&
                            headerMenus.length > 0 &&
                            headerMenus.map((data, index) =>
                                data.step === 'language' ||
                                data.step === 'region' ||
                                data.step === 'contact-center-type' ? (
                                    data.value &&
                                    data.value.length > 0 && (
                                        <MenuItem
                                            key={index}
                                            onClick={() =>
                                                sliderRef.slickGoTo(index)
                                            }>
                                            <a>
                                                <span>
                                                    {data.value[0] &&
                                                    data.step !== 'language'
                                                        ? data.value[0]
                                                        : data.value[0].label}
                                                </span>
                                                {data.value &&
                                                    data.value.length > 1 && (
                                                        <span>
                                                            , +
                                                            {data.value.length -
                                                                1}
                                                            &nbsp; more
                                                        </span>
                                                    )}
                                            </a>
                                        </MenuItem>
                                    )
                                ) : data.value ? (
                                    <MenuItem
                                        key={index}
                                        onClick={() =>
                                            sliderRef.slickGoTo(
                                                headerMenus[0].value ===
                                                    'Digital Marketing'
                                                    ? index - 5
                                                    : index
                                            )
                                        }>
                                        <a>{data.value && data.value}</a>
                                    </MenuItem>
                                ) : (
                                    data.info &&
                                    (data.info.first_name ||
                                        data.info.last_name ||
                                        data.info.email ||
                                        data.info.phone_number ||
                                        data.info.organization) && (
                                        <MenuItem
                                            key={index}
                                            onClick={() =>
                                                sliderRef.slickGoTo(
                                                    headerMenus[0].value ===
                                                        'Digital Marketing'
                                                        ? index - 5
                                                        : index - 4
                                                )
                                            }>
                                            <a>
                                                {data.info.first_name && (
                                                    <span>
                                                        {data.info.first_name}
                                                        <span>&nbsp;</span>
                                                    </span>
                                                )}
                                                {data.info.last_name && (
                                                    <span>
                                                        {data.info.last_name}
                                                    </span>
                                                )}
                                            </a>
                                        </MenuItem>
                                    )
                                )
                            )}
                        <CenterAligned2>
                            <Button
                                onClick={() => handleFinalAction('discard')}>
                                Discard
                            </Button>
                            <Button
                                onClick={() =>
                                    !submitting && handleFinalAction('submit')
                                }>
                                {(submitting && 'Submitting....') || 'Submit'}
                            </Button>
                        </CenterAligned2>
                    </CenterAligned>
                </ScrollWrapper>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    .modal.fade.show {
        display: flex !important;
        align-items: center;
    }
`;
const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 10%;
    }
`;
const ScrollWrapper = styled.div`
    width: 100%;
    ${Media('tabletlandscape')} {
        max-height: calc(100vh - 515px);
        overflow: auto;
    }
`;
const MenuItem = styled.div`
    display: inline-block;
    position: relative;
    width: 110px;
    word-break: break-word;
    text-overflow: ellipsis;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background-color: ${colors.pink['100']};
        width: calc(100% - 0px);
        left: 0px;
        bottom: -30px;
        transition: all 0.5s ease;
    }
    a {
        display: block;
        padding: 0 0px;
        position: relative;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroyMedium};
        font-size: 12px;
    }
    &:hover {
        a {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        padding-left: 0.5vw;
        padding-right: 0.5vw;
        margin: 0px 1vw;
        width: 5.789vw;
        &:after {
            bottom: -1.57vw;
        }
        a {
            font-size: 0.63vw;
        }
    }
    ${Media('lpscreens')} {
        &:after {
            bottom: -1.3vw;
        }
    }
    ${Media('desktopscreens')} {
        &:after {
            bottom: -1.1vw;
            height: 3px;
        }
        a {
            font-size: 1.1vw;
        }
    }
    ${Media('tabletlandscape')} {
        width: 100%;
        min-height: 60px;
        &:after {
            bottom: 0px;
        }
        a {
            padding-top: 20px;
        }
    }
`;
const CenterAligned2 = styled(CenterAligned)`
    position: absolute;
    bottom: 100px;
    ${Media('tabletlandscape')} {
        position: unset;
    }
`;
const Button = styled.div`
    height: 45px;
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.gray_dark['100']};
    color: ${colors.pink['100']};
    border-radius: 30px;
    margin: 0px 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.pink['100']};
        color: ${colors.white['100']};
        border: none;
    }
    ${Media('xlscreens')} {
        width: 10vw;
        height: 2.36vw;
        font-size: 0.73vw;
    }
    ${Media('tabletlandscape')} {
        margin: 10px 0px;
        width: 100%;
    }
`;

export default Step8;
