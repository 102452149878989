import React, { useState } from 'react';
import { Container } from '@bootstrap-styled/v4';

import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';

import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import {
    baseURL,
    handleDay,
    handleNextDay,
} from 'shared-components/theme/helpers';

const Step9 = ({ data, onClickAction }) => {
    const [userData] = useState(data[data.length - 1].info);

    return (
        <Wrapper>
            <Container>
                <BigText>
                    Thanks for getting <br /> in touch,&nbsp;
                    <div
                        style={{
                            display: 'inline',
                            textTransform: 'capitalize',
                        }}>
                        {userData.first_name}
                    </div>
                    <span>&nbsp;!</span>
                </BigText>
                <ListItems>
                    <p>
                        Curious about next steps? Here’s what will happen next:
                    </p>
                    <ul>
                        <li>
                            We’ll review your information. Yes, there will
                            probably be a monocle involved.
                        </li>
                        <li>
                            A member of our senior team will remove their
                            monocle and reach out to you directly. They’ll aim
                            to learn a little bit more about your needs, and
                            discover how ibex + {userData.organization} can
                            begin co-creating your program.
                        </li>
                        <li>
                            You’ll continue having a great&nbsp;
                            <span style={{ textTransform: 'capitalize' }}>
                                &nbsp;
                                {handleDay()}
                            </span>
                            &nbsp; :). And if
                            <span style={{ textTransform: 'capitalize' }}>
                                &nbsp;
                                {handleDay()}&nbsp;
                            </span>
                            isn’t going so well,&nbsp;
                            <span style={{ textTransform: 'capitalize' }}>
                                {handleNextDay()}&nbsp;
                            </span>
                            is right around the corner!
                        </li>
                    </ul>
                </ListItems>
                <CloseButton onClick={() => onClickAction('close')}>
                    Close
                </CloseButton>
                <BottomImage>
                    <img
                        src={`${baseURL}assets/images/bigger-circle.svg`}
                        alt=""
                    />
                </BottomImage>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 90px 0px;
    ${Media('xlscreens')} {
        padding: 4.73vw 0vw;
    }
    ${Media('tabletlandscape')} {
        padding: 50px 0px;
    }
`;
const BigText = styled.div`
    font-size: 77px;
    font-family: ${gilroyBold};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 4.05vw;
    }
    ${Media('tabletlandscape')} {
        font-size: 40px;
        br {
            display: none;
        }
    }
`;
const ListItems = styled.div`
    width: 50%;
    p {
        font-size: 13px;
        padding: 20px 0px;
    }
    ul {
        padding: 0px;
        li {
            padding: 10px 0px;
            padding-left: 20px;
            font-size: 13px;
            font-family: ${gilroyMedium};
            position: relative;
            line-height: 1.4;
            &:before {
                content: '';
                position: absolute;
                top: 14px;
                left: 0px;
                height: 7px;
                width: 7px;
                background-color: ${colors.pink['100']};
                border-radius: 10px;
                margin-right: 20px;
            }
        }
    }
    ${Media('xlscreens')} {
        p {
            font-size: 0.68vw;
            padding: 1.05vw 0vw;
        }
        ul li {
            padding: 0.52vw 0vw;
            font-size: 0.68vw;
            padding-left: 1.05vw;
            &:before {
                width: 0.36vw;
                height: 0.36vw;
                top: 0.82vw;
            }
        }
    }
    ${Media('tabletlandscape')} {
        width: 100%;
        ul {
            li {
                &:before {
                    margin-right: 5px;
                }
            }
        }
    }
`;
const CloseButton = styled.div`
    height: 45px;
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.gray_dark['100']};
    color: ${colors.pink['100']};
    border-radius: 30px;
    margin: 0px 10px;
    cursor: pointer;
    position: absolute;
    bottom: 100px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.pink['100']};
        color: ${colors.white['100']};
        border: none;
    }
    ${Media('xlscreens')} {
        width: 10vw;
        height: 2.36vw;
        font-size: 0.73vw;
    }
    ${Media('tabletlandscape')} {
        margin: 0px;
        margin-top: 40px;
        width: 100%;
        position: unset;
    }
`;
const BottomImage = styled.div`
    position: absolute;
    top: calc(5%);
    right: calc(-1%);
    height: 90%;
    width: auto;
    img {
        height: 100%;
        width: 100%;
    }
    ${Media('tabletlandscape')} {
        display: none;
    }
`;
export default Step9;
