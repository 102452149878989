import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const IndustryTag = ({ children, alignCentered, bigText }) => {
    return (
        <Wrapper alignCentered={alignCentered} bigText={bigText}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h5`
    color: ${colors.pink['100']};
    text-transform: uppercase;
    font-size: 14px;
    font-family: ${gilroyBold};
    letter-spacing: 2px;
    margin-top: 0;
    text-align: ${(props) => props.alignCentered && 'center'};
    span {
        text-transform: lowercase;
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigText ? '1.04vw' : '0.83vw')};
    }
    ${Media('mobile')} {
        margin-bottom: 0;
        text-align: center;
    }
`;
