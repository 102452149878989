const languages = [
    { code: "ab", label: "Abkhaz" },
    { code: "aa", label: "Afar" },
    { code: "af", label: "Afrikaans" },
    { code: "ak", label: "Akan" },
    { code: "sq", label: "Albanian" },
    { code: "am", label: "Amharic" },
    { code: "ar", label: "Arabic" },
    { code: "an", label: "Aragonese" },
    { code: "hy", label: "Armenian" },
    { code: "as", label: "Assamese" },
    { code: "av", label: "Avaric" },
    { code: "ae", label: "Avestan" },
    { code: "ay", label: "Aymara" },
    { code: "az", label: "Azerbaijani" },
    { code: "bm", label: "Bambara" },
    { code: "ba", label: "Bashkir" },
    { code: "eu", label: "Basque" },
    { code: "be", label: "Belarusian" },
    { code: "bn", label: "Bengali" },
    { code: "bh", label: "Bihari" },
    { code: "bi", label: "Bislama" },
    { code: "bs", label: "Bosnian" },
    { code: "br", label: "Breton" },
    { code: "bg", label: "Bulgarian" },
    { code: "my", label: "Burmese" },
    { code: "ca", label: "Catalan; Valencian" },
    { code: "ch", label: "Chamorro" },
    { code: "ce", label: "Chechen" },
    { code: "ny", label: "Chichewa; Chewa; Nyanja" },
    { code: "zh", label: "Chinese" },
    { code: "cv", label: "Chuvash" },
    { code: "kw", label: "Cornish" },
    { code: "co", label: "Corsican" },
    { code: "cr", label: "Cree" },
    { code: "hr", label: "Croatian" },
    { code: "cs", label: "Czech" },
    { code: "da", label: "Danish" },
    { code: "dv", label: "Divehi; Dhivehi; Maldivian;" },
    { code: "nl", label: "Dutch" },
    { code: "en", label: "English" },
    { code: "eo", label: "Esperanto" },
    { code: "et", label: "Estonian" },
    { code: "ee", label: "Ewe" },
    { code: "fo", label: "Faroese" },
    { code: "fj", label: "Fijian" },
    { code: "fi", label: "Finnish" },
    { code: "fr", label: "French" },
    { code: "ff", label: "Fula; Fulah; Pulaar; Pular" },
    { code: "gl", label: "Galician" },
    { code: "ka", label: "Georgian" },
    { code: "de", label: "German" },
    { code: "el", label: "Greek, Modern" },
    { code: "gn", label: "Guaraní" },
    { code: "gu", label: "Gujarati" },
    { code: "ht", label: "Haitian; Haitian Creole" },
    { code: "ha", label: "Hausa" },
    { code: "he", label: "Hebrew (modern)" },
    { code: "hz", label: "Herero" },
    { code: "hi", label: "Hindi" },
    { code: "ho", label: "Hiri Motu" },
    { code: "hu", label: "Hungarian" },
    { code: "ia", label: "Interlingua" },
    { code: "id", label: "Indonesian" },
    { code: "ie", label: "Interlingue" },
    { code: "ga", label: "Irish" },
    { code: "ig", label: "Igbo" },
    { code: "ik", label: "Inupiaq" },
    { code: "io", label: "Ido" },
    { code: "is", label: "Icelandic" },
    { code: "it", label: "Italian" },
    { code: "iu", label: "Inuktitut" },
    { code: "ja", label: "Japanese" },
    { code: "jv", label: "Javanese" },
    { code: "kl", label: "Kalaallisut, Greenlandic" },
    { code: "kn", label: "Kannada" },
    { code: "kr", label: "Kanuri" },
    { code: "ks", label: "Kashmiri" },
    { code: "kk", label: "Kazakh" },
    { code: "km", label: "Khmer" },
    { code: "ki", label: "Kikuyu, Gikuyu" },
    { code: "rw", label: "Kinyarwanda" },
    { code: "ky", label: "Kirghiz, Kyrgyz" },
    { code: "kv", label: "Komi" },
    { code: "kg", label: "Kongo" },
    { code: "ko", label: "Korean" },
    { code: "ku", label: "Kurdish" },
    { code: "kj", label: "Kwanyama, Kuanyama" },
    { code: "la", label: "Latin" },
    { code: "lb", label: "Luxembourgish, Letzeburgesch" },
    { code: "lg", label: "Luganda" },
    { code: "li", label: "Limburgish, Limburgan, Limburger" },
    { code: "ln", label: "Lingala" },
    { code: "lo", label: "Lao" },
    { code: "lt", label: "Lithuanian" },
    { code: "lu", label: "Luba-Katanga" },
    { code: "lv", label: "Latvian" },
    { code: "gv", label: "Manx" },
    { code: "mk", label: "Macedonian" },
    { code: "mg", label: "Malagasy" },
    { code: "ms", label: "Malay" },
    { code: "ml", label: "Malayalam" },
    { code: "mt", label: "Maltese" },
    { code: "mi", label: "Māori" },
    { code: "mr", label: "Marathi (Marāṭhī)" },
    { code: "mh", label: "Marshallese" },
    { code: "mn", label: "Mongolian" },
    { code: "na", label: "Nauru" },
    { code: "nv", label: "Navajo, Navaho" },
    { code: "nb", label: "Norwegian Bokmål" },
    { code: "nd", label: "North Ndebele" },
    { code: "ne", label: "Nepali" },
    { code: "ng", label: "Ndonga" },
    { code: "nn", label: "Norwegian Nynorsk" },
    { code: "no", label: "Norwegian" },
    { code: "ii", label: "Nuosu" },
    { code: "nr", label: "South Ndebele" },
    { code: "oc", label: "Occitan" },
    { code: "oj", label: "Ojibwe, Ojibwa" },
    { code: "cu", label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic" },
    { code: "om", label: "Oromo" },
    { code: "or", label: "Oriya" },
    { code: "os", label: "Ossetian, Ossetic" },
    { code: "pa", label: "Panjabi, Punjabi" },
    { code: "pi", label: "Pāli" },
    { code: "fa", label: "Persian" },
    { code: "pl", label: "Polish" },
    { code: "ps", label: "Pashto, Pushto" },
    { code: "pt", label: "Portuguese" },
    { code: "qu", label: "Quechua" },
    { code: "rm", label: "Romansh" },
    { code: "rn", label: "Kirundi" },
    { code: "ro", label: "Romanian, Moldavian, Moldovan" },
    { code: "ru", label: "Russian" },
    { code: "sa", label: "Sanskrit (Saṁskṛta)" },
    { code: "sc", label: "Sardinian" },
    { code: "sd", label: "Sindhi" },
    { code: "se", label: "Northern Sami" },
    { code: "sm", label: "Samoan" },
    { code: "sg", label: "Sango" },
    { code: "sr", label: "Serbian" },
    { code: "gd", label: "Scottish Gaelic; Gaelic" },
    { code: "sn", label: "Shona" },
    { code: "si", label: "Sinhala, Sinhalese" },
    { code: "sk", label: "Slovak" },
    { code: "sl", label: "Slovene" },
    { code: "so", label: "Somali" },
    { code: "st", label: "Southern Sotho" },
    { code: "es", label: "Spanish; Castilian" },
    { code: "su", label: "Sundanese" },
    { code: "sw", label: "Swahili" },
    { code: "ss", label: "Swati" },
    { code: "sv", label: "Swedish" },
    { code: "ta", label: "Tamil" },
    { code: "te", label: "Telugu" },
    { code: "tg", label: "Tajik" },
    { code: "th", label: "Thai" },
    { code: "ti", label: "Tigrinya" },
    { code: "bo", label: "Tibetan Standard, Tibetan, Central" },
    { code: "tk", label: "Turkmen" },
    { code: "tl", label: "Tagalog" },
    { code: "tn", label: "Tswana" },
    { code: "to", label: "Tonga (Tonga Islands)" },
    { code: "tr", label: "Turkish" },
    { code: "ts", label: "Tsonga" },
    { code: "tt", label: "Tatar" },
    { code: "tw", label: "Twi" },
    { code: "ty", label: "Tahitian" },
    { code: "ug", label: "Uighur, Uyghur" },
    { code: "uk", label: "Ukrainian" },
    { code: "ur", label: "Urdu" },
    { code: "uz", label: "Uzbek" },
    { code: "ve", label: "Venda" },
    { code: "vi", label: "Vietnamese" },
    { code: "vo", label: "Volapük" },
    { code: "wa", label: "Walloon" },
    { code: "cy", label: "Welsh" },
    { code: "wo", label: "Wolof" },
    { code: "fy", label: "Western Frisian" },
    { code: "xh", label: "Xhosa" },
    { code: "yi", label: "Yiddish" },
    { code: "yo", label: "Yoruba" },
    { code: "za", label: "Zhuang, Chuang" },
];
languages.forEach(a=>{
    a.value=a.label.toLowerCase();
})
export default languages;