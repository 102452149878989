import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, Input } from '@bootstrap-styled/v4';
import colors from '../theme/colors';
import Media from '../theme/medias';

const Ibinput = (props) => {
    return (
        <FormGroupCustom>
            {props.select ? (
                <SelectCustom
                    as="select"
                    value={props.value}
                    required
                    
                    {...props}>
                    <option value="" hidden></option>
                    {props.isAll && <option value="all">All</option>}
                    {props.options &&
                        props.options.length > 0 &&
                        props.options.map((item, index) => {
                            return (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            );
                        })}
                </SelectCustom>
            ) : (
                <InputCustom
                    value={props.value}
                    required
                    {...props}
                    onChange={props?.onChange}
                    style={props?.style}
                />
            )}
            <LabelCustom color={props.color} opacity={props.label_opacity}>
                {props.label}
            </LabelCustom>
            <Bar color={props.color}></Bar>
        </FormGroupCustom>
    );
};

const FormGroupCustom = styled(FormGroup)`
    position: relative;
    margin-top: 2rem;
    margin-bottom: 0;
`;

const LabelCustom = styled(Label)`
    position: absolute;
    top: 0.8rem;
    pointer-events: none;
    z-index: 1;
    opacity: ${(props) => (props.opacity ? props.opacity : '0.4')};
    font-size: 14px;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    ${Media('large')} {
        font-size: 18px;
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color} !important`;
        } else {
            return `color:${colors.gray_dark['100']}`;
        }
    }}
`;

const Bar = styled.i`
    position: relative;
    display: block;
    &:before {
        content: '';
        height: 0.125rem;
        width: 0;
        left: 50%;
        bottom: -0.0625rem;
        position: absolute;
        background: ${colors.pink['100']};
        transition: left 0.28s ease, width 0.28s ease;
        z-index: 2;
    }
    ${(props) => {
        if (props && props.color) {
            return `border-bottom:0.0625rem solid ${props.color} !important`;
        } else {
            return `border-bottom:0.0625rem solid ${colors.gray_dark['100']}`;
        }
    }}
`;

const InputCustom = styled(Input)`
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 14px;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    transition: all 0.28s ease;
    box-shadow: none;
    min-height: 40px;
    &:focus,
    &:valid {
        outline: none;
        & ~ ${LabelCustom} {
            font-size: 0.8rem;
            color: ${colors.pink['100']};
            top: -0.5rem;
            left: 0;
            ${Media('large')} {
                font-size: 12px;
            }
        }
        & ~ ${Bar} {
            &:before {
                width: 100%;
                left: 0;
            }
        }
    }
    ${Media('xlscreens')} {
        min-height: 2.3vw;
    }
    ${Media('large')} {
        font-size: 18px;
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color} !important`;
        } else {
            return `color:${colors.gray_dark['100']}`;
        }
    }}
`;

const SelectCustom = styled(InputCustom)``;

export default Ibinput;
