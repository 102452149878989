import React, { useRef } from 'react';
import Slider from 'react-slick';
import { baseURL } from 'shared-components/theme/helpers';
import styled from 'styled-components';
import colors from '../theme/colors';
import Media from '../theme/medias';

const SlickSlider = ({ children, settings, refs }) => {
    const sliderRef = useRef(null);
    if (refs) {
        refs(sliderRef);
    }
    return (
        <Wrapper>
            <Slider {...settings} ref={sliderRef}>
                {children}
            </Slider>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .slick-arrow {
        width: 35px;
        height: 35px;
        font-size: 0;
        border: 1px solid ${colors.gray_dark['100']};
        border-radius: 50%;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        transform: scale(1);
        outline: 0;
        &:before {
            content: '';
            background-image: url('${baseURL}assets/images/arrow_right-blue.svg');
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        &.slick-prev {
            &:before {
                transform: rotate(180deg);
            }
        }
        &:hover,
        &:focus {
            background-color: ${colors.white['100']};
            outline: 0;
        }
        ${Media('large')} {
            width: 46px;
            height: 46px;
            &:before {
                background-size: 25px;
            }
        }
    }
`;

export default SlickSlider;
