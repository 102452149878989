import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';

export const EbookScrollDown = ({ color, bgColor, animate }) => {
    return (
        <Wrapper color={color} bgColor={bgColor} animate={animate}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.103"
                height="36.333"
                viewBox="0 0 21.103 36.333">
                <g id="scroll-down-icon" transform="translate(0)">
                    <g
                        id="Group_627"
                        dataName="Group 627"
                        transform="translate(0 0)">
                        <path
                            id="Path_1437"
                            dataName="Path 1437"
                            d="M624.644,77.076h-1.375a.562.562,0,0,1,0-1.125h1.375a6.853,6.853,0,0,0,6.85-7.018L631.1,52.715a6.812,6.812,0,0,0-6.85-6.687h-4.365a6.813,6.813,0,0,0-6.85,6.687l-.391,16.218a6.853,6.853,0,0,0,6.85,7.018h1.375a.562.562,0,1,1,0,1.125H619.5a7.977,7.977,0,0,1-7.975-8.17l.392-16.218a7.93,7.93,0,0,1,7.975-7.785h4.365a7.931,7.931,0,0,1,7.975,7.785l.391,16.218a7.978,7.978,0,0,1-7.975,8.17Z"
                            transform="translate(-611.518 -44.903)"
                            fill="#212121"
                        />
                    </g>
                    <g
                        id="Group_628"
                        dataName="Group 628"
                        transform="translate(8.637 3.423)">
                        <path
                            id="Path_1438"
                            dataName="Path 1438"
                            d="M636.468,61.1h0a1.916,1.916,0,0,1-1.913-1.913V55.945a1.914,1.914,0,0,1,3.828,0v3.246A1.916,1.916,0,0,1,636.468,61.1Zm0-5.947a.79.79,0,0,0-.79.789v3.246a.789.789,0,0,0,.789.788h0a.789.789,0,0,0,.788-.788V55.945A.79.79,0,0,0,636.468,55.157Z"
                            transform="translate(-634.553 -54.032)"
                            fill="#212121"
                        />
                    </g>
                    <g
                        id="Group_629"
                        dataName="Group 629"
                        transform="translate(10.551 33.646)">
                        <path
                            id="Path_1439"
                            dataName="Path 1439"
                            d="M639.658,134.631"
                            transform="translate(-639.658 -134.631)"
                            fill="none"
                            stroke="#212121"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="3"
                        />
                    </g>
                    <g
                        id="Group_630"
                        dataName="Group 630"
                        transform="translate(9.988 16.086)">
                        <path
                            id="Path_1440"
                            dataName="Path 1440"
                            d="M638.158,106.926,638.72,87.8l.562,19.123a.563.563,0,0,1-1.125.033C638.157,106.948,638.157,106.936,638.158,106.926Z"
                            transform="translate(-638.157 -87.803)"
                            fill="#212121"
                        />
                    </g>
                    <g
                        id="Group_631"
                        dataName="Group 631"
                        transform="translate(8.441 33.387)">
                        <path
                            id="Path_1441"
                            dataName="Path 1441"
                            d="M636.182,136.887,634.2,134.9a.562.562,0,1,1,.8-.8l1.191,1.19,1.157-1.158a.562.562,0,0,1,.8.8Z"
                            transform="translate(-634.03 -133.941)"
                            fill="#212121"
                        />
                    </g>
                </g>
            </svg>
        </Wrapper>
    );
};

const Wrapper = styled(FlexCentered)`
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.ebook.text};
    width: 86px;
    height: 86px;
    border-radius: 100%;
    animation: ${(props) => props.animate && 'down 2s infinite'};
    svg {
        width: 25px;
        path {
            fill: ${(props) => props.color};
        }
    }
    @keyframes down {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateY(15px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateY(15px);
        }
        100% {
            transform: translate(0);
        }
    }
    ${Media('xlscreens')} {
        width: 4.4vw;
        height: 4.4vw;
        svg {
            width: 1.3vw;
        }
    }
    ${Media('tablet')} {
        height: 60px;
        width: 60px;
        svg {
            width: 18px;
        }
    }
    ${Media('mobile')} {
        height: 45px;
        width: 45px;
        svg {
            width: 14px;
        }
    }
`;
