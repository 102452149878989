import { createGlobalStyle } from 'styled-components';
import colors from './shared-components/theme/colors';
import {
    gilroyRegular,
    gilroySemibold,
    gilroyMedium,
    gilroyBold,
} from './shared-components/theme/fonts';
import Media from './shared-components/theme/medias';

export default createGlobalStyle`
    html, body {
        height: 100%;
    }
    * {
        list-style: none;
    }
    body{
		margin: 0px;
        padding: 0px;
        font-family: ${gilroyRegular};
        font-weight: 400;
        font-size: 14px;
        overflow-x: hidden;  
        background-color: ${colors.gray_lighter['100']};
        color: ${colors.gray_dark['100']};
    }
    #main {
        background-color: ${colors.gray_lighter['100']};
        position: relative;
        z-index: 999;
        padding-bottom: 1px;
        ${Media('tablet')} {
            overflow-x: hidden;
        }
    }
    a {
        text-decoration: none;
        cursor: pointer;
        color: ${colors.gray_dark['100']};
        transition-duration: 0.3s;
    }
    a:hover {
        color: ${colors.pink['100']};
    }
    img {
        display: block;
    }
    input:focus,
    button:focus,
    select:focus,
    .slick-slide:focus {
        outline: none;
        box-shadow: none;
    }
    h1, h2 {
        font-size: 60px;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroySemibold};
        line-height: 1.1;
        margin: 30px 0;
        -webkit-font-smoothing: antialiased;
        font-weight: 500;
        ${Media('large')} {
            font-size: 80px;
        }
        ${Media('mobile')} {
            font-size: 36px;
        }
    }
    h3{
        font-size: 52px;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroySemibold};
        margin: 15px 0;
        line-height: 1.3;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        ${Media('tablet')} {
            font-size: 18px;
        }
        ${Media('mobile')} {
            font-size: 18px;
        }
    }
    h4 {
        font-size: 40px;
        font-family: ${gilroyBold};
        -webkit-font-smoothing: antialiased;
          ${Media('tablet')} {
            font-size: 2.10vw;
        }
        ${Media('mobile')} {
            font-size: 12px;
        }
    }
    p {
        font-size: 14px;
        line-height: 1.6;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroyMedium};
        -webkit-font-smoothing: antialiased;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
        ${Media('desktopscreens')} {
            font-size: 1.1vw;
        }
    }
    b, strong {
        font-family: ${gilroyBold};
    }
    section {
        padding: 100px 0px;
        ${Media('xlscreens')} {
            padding: 5vw 0;
        }
        ${Media('tablet')} {
            padding: 60px 0px;
        }
    }

    sup{
        font-size: 12px;
        position: relative;
        top: 2px;
        ${Media('xlscreens')} {
            font-size: 0.55vw;
            top: 0.1vw;
        }
        ${Media('tablet')} {
            font-size: 10px;
        }
    }

    .container, .container-fluid {
        box-sizing: border-box;
    }
    .container {
        ${Media('xlscreens')} {
            max-width: 100% !important;
            padding: 0 13vw !important;
            box-sizing: border-box;
        }
    }

    #main {
        ${Media('tablet')} {
            padding-top: 82px;
        }
    }
    .osano-cm-header{
        font-size: 20px !important;
        font-family: ${gilroySemibold};
    }
    .osano-cm-description{
        font-size: 14px !important;
        p{
            font-size: 14px !important;
        }
    } 
    .osano-cm-label{
        font-size: 18px !important;
        font-family: ${gilroySemibold};
    }
    #osano-cm-consent--view{
        margin-bottom: 30px !important;
    }
    .track-on-window-two {
        width: 100%;
    }
`;
