import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';

export const IndustryPerformanceBox = ({
    count,
    image,
    title,
    list,
    noMarginBottom,
}) => {
    return (
        <PerformanceBox noMarginBottom={noMarginBottom}>
            {count && <PerformanceCount>{count}</PerformanceCount>}
            <PerformanceImg>
                <img src={image} alt="-" />
            </PerformanceImg>
            <PerformanceTitle>{ReactHtmlParser(title)}</PerformanceTitle>
            <PerformanceList>
                {list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                })}
            </PerformanceList>
        </PerformanceBox>
    );
};

const PerformanceBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '70px')};
    ${Media('xlscreens')} {
        margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '5.2vw')};
    }
    ${Media('mobile')} {
        margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '50px')};
    }
`;
const PerformanceCount = styled.h4`
    font-size: 26px;
    font-family: ${gilroyBold};
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 1.77vw;
    }
`;
const PerformanceImg = styled.div`
    margin-bottom: 30px;
    height: 150px;
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.1vw;
        height: 10.93vw;
        img {
            min-width: 10.4vw;
            max-width: 11.2vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 100%;
        }
    }
`;
const PerformanceTitle = styled.h3`
    font-size: 22px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 1.2vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;
const PerformanceList = styled.ul`
    padding: 0px;
    margin: 1em 0;
    li {
        font-size: 14px;
        font-family: ${gilroyMedium};
        color: ${colors.dark_brown['100']};
    }
    ${Media('xlscreens')} {
        margin: 0.85vw 0;
        li {
            font-size: 0.83vw;
        }
    }
    ${Media('tablet')} {
        padding: 0px 20px;
    }
`;
