import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import Slider from 'rc-slider';
import { gilroyMedium } from 'shared-components/theme/fonts';

import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const Step3 = ({ onSelect }) => {
    const [currentVal, setVal] = useState(0);
    const handleSliderChange = (val) => {
        setVal(val);
        onSelect(val + 'k+');
    };
    return (
        <Wrapper>
            <BuildYourCXInfo2 image={`${baseURL}assets/images/add-spent.jpg`} />
            <SelectionWrapper>
                <SubHeading content="What is your average monthly spend?" />
                <Row>
                    <CenterAligned>
                        <Col lg="4" xs="10">
                            <SliderLabel val={currentVal}>
                                {(currentVal !== 0 && currentVal + 'k+') || 0}
                            </SliderLabel>
                            {currentVal !== 500 && (
                                <SliderRange>500k+</SliderRange>
                            )}
                            <Slider
                                onChange={handleSliderChange}
                                min={0}
                                max={500}
                                step={25}
                                dots
                            />
                        </Col>
                    </CenterAligned>
                </Row>
            </SelectionWrapper>
            <BottomImage>
                <img src={`${baseURL}assets/images/big-ellipse.png`} alt="" />
            </BottomImage>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const SliderLabel = styled.div`
    margin-left: -10px;
    margin-bottom: 10px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    font-size: 20px;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-bottom: 0.52vw;
        margin-left: -0.52vw;
    }
    ${Media('tabletlandscape')} {
        font-size: 14px;
    }
    ${(props) => {
        return `padding-left:${(props.val / 500) * 100}%`;
    }}
`;
const SliderRange = styled.div`
    position: absolute;
    right: -10px;
    bottom: 25px;
    color: ${colors.gray['100']};
    font-family: ${gilroyMedium};
    font-size: 14px;
    ${Media('xlscreens')} {
        font-size: 0.84vw;
        bottom: 1.31vw;
    }
`;
const BottomImage = styled.div`
    height: auto;
    width: 350px;
    bottom: 0px;
    transform: translateX(81vw) translateY(5vw);
    img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        height: 15.78vw;
        width: 18.42vw;
    }
    ${Media('tabletlandscape')} {
        display: none;
    }
`;
const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const SelectionWrapper = styled.div`
    padding-top: 70px;
    ${Media('xlscreens')} {
        padding-top: 3.68vw;
    }
    ${Media('tabletlandscape')} {
        padding: 20px 0px;
    }
`;
export default Step3;
