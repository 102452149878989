import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { gilroyBold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

export const EbookBigText = ({ children, color, reverse, stroke }) => {
    return (
        <Wrapper
            x={reverse ? ['50%', '-50%'] : ['-50%', '50%']}
            color={color}
            stroke={stroke}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(Parallax)`
    .parallax-inner {
        font-size: 400px;
        font-family: ${gilroyBold};
        line-height: 1;
        color: ${(props) => props.color};
        -webkit-text-stroke: 1px
            ${(props) => (props.stroke ? props.stroke : colors.ebook.text)};
        white-space: nowrap;
    }
    ${Media('xlscreens')} {
        .parallax-inner {
            font-size: 21vw;
        }
    }
    ${Media('tablet')} {
        .parallax-inner {
            font-size: 280px;
        }
    }
    ${Media('mobile')} {
        .parallax-inner {
            font-size: 150px;
        }
    }
`;
