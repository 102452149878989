import React, { useState } from 'react';
import styled from 'styled-components';
import SlickSlider from 'shared-components/slider/slider';
import BuildYourCXHeader from './buildyourCX-header/buildyourCX-header';
import BuildyourCXTeamStep1 from './buildyourCX-step1/buildyourCX-step1';
import BuildyourCXTeamStep2 from './buildyourCX-step2/buildyourCX-step2';
import BuildyourCXTeamStep3 from './buildyourCX-step3/buildyourCX-step3';
import BuildyourCXTeamStep4 from './buildyourCX-step4/buildyourCX-step4';
import BuildyourCXTeamStep5 from './buildyourCX-step5/buildyourCX-step5';
import BuildyourCXTeamStep6 from './buildyourCX-step6/buildyourCX-step6';
import BuildyourCXTeamStep7 from './buildyourCX-step7/buildyourCX-step7';
import BuildyourCXTeamStep8 from './buildyourCX-step8/buildyourCX-step8';
import BuildyourCXTeamStep9 from './buildyourCX-step9/buildyourCX-step9';
import BuildYourCXTeamDStep1 from './buildyourCX-digital-step1/buildyourCX-digital-step1';
import BuildYourCXTeamDStep2 from './buildyourCX-digital-step2/buildyourCX-digital-step2';
import BuildYourCXTeamDStep3 from './buildyourCX-digital-step3/buildyourCX-digital-step3';
import BuildYourCXTeamDStep4 from './buildyourCX-digital-step4/buildyourCX-digital-step4';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import fetch from 'cross-fetch';

let hide = false;
let currentSlide = 0;
let slidesType = null;

const NextArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: hide ? 'none' : 'block',
                left: currentSlide === 0 && 'calc(67% - 190px)',
            }}
            onClick={onClick}
        />
    );
};
const PrevArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: hide ? 'none' : 'block',
                left: currentSlide === 0 && 'calc(67% - 190px)',
            }}
            onClick={onClick}
        />
    );
};
const settingsCXForm = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerPadding: '0px',
    focusOnSelect: false,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: false,
    beforeChange: (current, next) => slideChange(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};

const slideChange = (next) => {
    currentSlide = next;
    if (slidesType === 'Digital Marketing') {
        if (next > 5) {
            return (hide = true);
        } else {
            return (hide = false);
        }
    } else {
        if (next > 6) {
            return (hide = true);
        } else {
            return (hide = false);
        }
    }
};
const data = [
    {
        step: 'type',
        value: null,
    },
    {
        step: 'contact-center-type',
        value: [],
    },
    {
        step: 'count',
        value: null,
    },
    {
        step: 'region',
        value: [],
    },
    {
        step: 'language',
        value: [],
    },
    {
        step: 'post-sending',
        value: null,
    },
    {
        step: 'ad-type',
        value: null,
    },
    {
        step: 'ad-spent',
        value: null,
    },
    {
        step: 'programme-goal',
        value: null,
    },
    {
        step: 'agent-requirement',
        value: null,
    },
    {
        step: 'about',
        info: {
            first_name: '',
            last_name: '',
            email: '',
            confirm_email: '',
            phone_number: '',
            organization: '',
        },
    },
];
const BuildYourCX = ({ state, closeForm, page }) => {
    const [headerData, setHeaderData] = useState(data);
    const [hideHeaderData, setHideHeaderData] = useState(false);
    const [sliderRef, setSliderRef] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [inValidEmail, setInValidEmail] = useState(false);
    const [inValidCEmail, setInValidCEmail] = useState(false);
    const [inValidPhone, setInValidPhone] = useState(false);
    const [emailNotMatched, setEmailNotMatched] = useState(false);

    const getNextSlideIndex = (current) => {
        if (slidesType === 'Digital Marketing') {
            if (current > 5) {
                setHideHeaderData(true);
            } else {
                setHideHeaderData(false);
            }
        } else {
            if (current > 6) {
                setHideHeaderData(true);
            } else {
                setHideHeaderData(false);
            }
        }
    };
    settingsCXForm.afterChange = getNextSlideIndex;
    const getRefs = (ref) => {
        if (ref && ref.current) {
            setSliderRef(ref.current);
        }
    };

    // Validation functions
    const emailValidation = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const phoneValid = (val) => {
        const numbers = /^[0-9]+$/;
        if (val.match(numbers) || val === '') {
            return true;
        } else {
            return false;
        }
    };
    const setValuetoState = (value, step, field) => {
        const arr = [...headerData];
        if (step === 'type') {
            slidesType = value;
            arr.forEach((data) => {
                if (data.step === 'type') {
                    if (data.value && data.value !== value) {
                        arr.map((a) => {
                            if (a.step !== 'type') {
                                if (
                                    a.step == 'language' ||
                                    a.step === 'region' ||
                                    a.step === 'contact-center-type'
                                ) {
                                    a.value = [];
                                } else if (a.step === 'about') {
                                    a.info.first_name = '';
                                    a.info.last_name = '';
                                    a.info.email = '';
                                    a.info.phone_number = '';
                                    a.info.organization = '';
                                    a.info.confirm_email = '';
                                } else {
                                    a.value = null;
                                }
                            }
                        });
                        return (data.value = value);
                    } else {
                        return (data.value = value);
                    }
                }
            });
        } else {
            arr.forEach((data) => {
                if (data && data.step === step) {
                    if (data.step === 'about') {
                        const info = data.info;
                        const ve = emailValidation(value);
                        if (field === 'phone_number') {
                            const pv = phoneValid(value);
                            if (!pv) {
                                setInValidPhone(true);
                            } else {
                                setInValidPhone(false);
                            }
                        } else {
                            if (
                                field === 'email' ||
                                field === 'confirm_email'
                            ) {
                                setEmailNotMatched(false);
                                if (!ve) {
                                    if (field === 'email') {
                                        setInValidEmail(true);
                                    } else {
                                        setInValidCEmail(true);
                                    }
                                } else {
                                    if (field === 'email') {
                                        setInValidEmail(false);
                                    } else {
                                        setInValidCEmail(false);
                                    }
                                }
                            }
                        }
                        info[field] = value;
                    } else if (data.step === 'language') {
                        data.value = value;
                    } else if (
                        data.step === 'region' ||
                        data.step === 'contact-center-type'
                    ) {
                        if (!data.value.includes(value)) {
                            data.value.push(value);
                        } else {
                            const index = data.value.indexOf(value);
                            if (index > -1) {
                                data.value.splice(index, 1);
                            }
                        }
                    } else {
                        data.value = value;
                    }
                }
            });
        }
        setHeaderData(arr);
    };
    const type = headerData[0].value;
    const handleFormAction = (action) => {
        const arr = [...headerData];
        if (action && (action === 'discard' || action === 'close')) {
            arr.forEach((data) => {
                if (
                    data.step &&
                    (data.step === 'language' ||
                        data.step === 'region' ||
                        data.step === 'contact-center-type')
                ) {
                    data.value = [];
                } else if (data.step && data.step === 'about') {
                    data.info.first_name = '';
                    data.info.last_name = '';
                    data.info.email = '';
                    data.info.phone_number = '';
                    data.info.organization = '';
                    data.info.confirm_email = '';
                } else {
                    data.value = null;
                }
            });
            setFormSubmitted(false);
            setEmailNotMatched(false);
            if (action === 'close') {
                closeForm();
            } else {
                sliderRef.slickGoTo(0);
            }
        } else {
            const data = [...headerData];
            if (data[10].info.email !== data[10].info.confirm_email) {
                alert('Email Not Matched.');
                setEmailNotMatched(true);
                sliderRef.slickGoTo(
                    data[0].value === 'Digital Marketing' ? 5 : 6
                );
            } else {
                setEmailNotMatched(false);
                const about = [
                    {
                        label: 'First Name',
                        name: 'first_name',
                        value: data[10].info.first_name,
                    },
                    {
                        label: 'Last Name',
                        name: 'last_name',
                        value: data[10].info.last_name,
                    },
                    {
                        label: 'Email',
                        name: 'email_address',
                        value: data[10].info.email,
                    },
                    {
                        label: 'Phone Number',
                        name: 'phone_number',
                        value: data[10].info.phone_number,
                    },
                    {
                        label: 'Organization',
                        name: 'organization',
                        value: data[10].info.organization,
                    },
                ];
                const finalObj = {};
                if (data[0].value === 'Digital Marketing') {
                    finalObj.digital_marketing = [
                        {
                            label: 'What type of advertising do you run today?',
                            name: data[6].step,
                            value: data[6].value,
                        },
                        {
                            label: 'What is your average monthly spend?',
                            name: data[7].step,
                            value: data[7].value,
                        },
                        {
                            label: 'What is the goal of your program?',
                            name: data[8].step,
                            value: data[8].value,
                        },
                        {
                            label:
                                'Will any of your programs require Contact Center Sales Agents?',
                            name: data[9].step,
                            value: data[9].value,
                        },
                    ];
                    about.map((a) => finalObj.digital_marketing.push(a));
                } else {
                    finalObj.contact_center = [
                        {
                            label:
                                'What type of Contact Center team(s) are you looking to build?',
                            name: data[1].step,
                            value: data[1].value,
                        },
                        {
                            label:
                                'How many contact center agents will you need?',
                            name: data[2].step,
                            value: data[2].value,
                        },
                        {
                            label:
                                'In what regions are you interested in operating?',
                            name: data[3].step,
                            value: data[3].value,
                        },
                        {
                            label: 'What languages will you require?',
                            name: data[4].step,
                            value: data[4].value.map((value) => {
                                return value.label;
                            }),
                        },
                        {
                            label:
                                'Are you interested in sending post-contact 5-Star customer surveys to measure customer satisfaction?',
                            name: data[5].step,
                            value: data[5].value,
                        },
                    ];
                    about.map((a) => finalObj.contact_center.push(a));
                }

                // email form data
                submitCXFormData(finalObj);
            }
        }
        setHeaderData(arr);
    };
    const cxGroupTeamVal =
        headerData[1].value && headerData[1].value.length > 0 ? true : false;
    const cxGroupCountVal = headerData[2].value ? true : false;
    const cxGroupRegionVal =
        headerData[3].value && headerData[3].value.length > 0 ? true : false;
    const cxGroupLangVal =
        headerData[4].value && headerData[4].value.length > 0 ? true : false;
    const cxGroupPostVal = headerData[5].value ? true : false;
    const cxGroupInfoVal =
        headerData[10].info &&
        !inValidEmail &&
        !inValidCEmail &&
        !inValidPhone &&
        headerData[10].info.first_name !== '' &&
        headerData[10].info.last_name !== '' &&
        headerData[10].info.email !== '' &&
        headerData[10].info.confirm_email !== '' &&
        headerData[10].info.phone_number !== '' &&
        headerData[10].info.organization !== ''
            ? true
            : false;

    // CX FORM SUBMISSION HANDLERS
    const submitCXFormData = async (CXData) => {
        let formEndpoint = null;
        if (typeof CXData.contact_center !== 'undefined') {
            formEndpoint = `${process.env.REACT_APP_IBEX_API}/cx-form?token=${
                process.env.REACT_APP_API_TOKEN
            }&referrer=${page}&contact_center=${encodeURIComponent(
                JSON.stringify(CXData.contact_center)
            )}`;
        } else if (typeof CXData.digital_marketing !== 'undefined') {
            formEndpoint = `${process.env.REACT_APP_IBEX_API}/cx-form?token=${
                process.env.REACT_APP_API_TOKEN
            }&referrer=${page}&digital_marketing=${encodeURIComponent(
                JSON.stringify(CXData.digital_marketing)
            )}`;
        }

        try {
            const requestOptions = {
                method: 'POST',
            };
            const res = await fetch(formEndpoint, requestOptions);
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setFormSubmitted(true);
                sliderRef.slickNext();
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Wrapper open={state} title="Build Your CX Team">
            <BuildYourCXHeader
                hideHeaderData={hideHeaderData}
                headerMenus={headerData}
                closeForm={() => handleFormAction('close')}
            />
            <SliderWrapper>
                <SlickSlider settings={settingsCXForm} refs={(r) => getRefs(r)}>
                    <Slide>
                        {headerData && headerData.length > 0 && (
                            <BuildyourCXTeamStep1
                                onSelect={(value) =>
                                    setValuetoState(value, 'type')
                                }
                                selected={headerData[0]}
                            />
                        )}
                    </Slide>

                    {/* Digital Marketing Slides */}

                    {type && type === 'Digital Marketing' && (
                        <Slide>
                            <BuildYourCXTeamDStep1
                                onSelect={(value) =>
                                    setValuetoState(value, 'ad-type')
                                }
                                selected={headerData[6]}
                            />
                        </Slide>
                    )}
                    {type &&
                        type === 'Digital Marketing' &&
                        headerData[6].value && (
                            <Slide>
                                <BuildYourCXTeamDStep2
                                    onSelect={(value) =>
                                        setValuetoState(value, 'ad-spent')
                                    }
                                    selected={headerData[7]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Digital Marketing' &&
                        headerData[7].value && (
                            <Slide>
                                <BuildYourCXTeamDStep3
                                    onSelect={(value) =>
                                        setValuetoState(value, 'programme-goal')
                                    }
                                    selected={headerData[8]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Digital Marketing' &&
                        headerData[8].value && (
                            <Slide>
                                <BuildYourCXTeamDStep4
                                    onSelect={(value) =>
                                        setValuetoState(
                                            value,
                                            'agent-requirement'
                                        )
                                    }
                                    selected={headerData[9]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Digital Marketing' &&
                        headerData[9].value && (
                            <Slide>
                                <BuildyourCXTeamStep7
                                    inValidEmail={inValidEmail}
                                    inValidCEmail={inValidCEmail}
                                    inValidPhone={inValidPhone}
                                    emailNotMatched={emailNotMatched}
                                    onSelect={(value, field) =>
                                        setValuetoState(value, 'about', field)
                                    }
                                    details={headerData[10]}
                                />
                            </Slide>
                        )}
                    {type && type === 'Digital Marketing' && cxGroupInfoVal && (
                        <Slide>
                            <BuildyourCXTeamStep8
                                formSubmitted={formSubmitted}
                                sliderRef={sliderRef}
                                headerMenus={headerData}
                                onClickAction={(val) => handleFormAction(val)}
                            />
                        </Slide>
                    )}

                    {/* Contact Center Slides */}

                    {type && type === 'Contact Center Outsourcing' && (
                        <Slide>
                            <BuildyourCXTeamStep2
                                onSelect={(value) =>
                                    setValuetoState(
                                        value,
                                        'contact-center-type'
                                    )
                                }
                                selected={headerData[1]}
                            />
                        </Slide>
                    )}
                    {type &&
                        type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal && (
                            <Slide>
                                <BuildyourCXTeamStep3
                                    onSelect={(value) =>
                                        setValuetoState(value, 'count')
                                    }
                                    selected={headerData[2]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal &&
                        cxGroupCountVal && (
                            <Slide>
                                <BuildyourCXTeamStep4
                                    onSelect={(value) =>
                                        setValuetoState(value, 'region')
                                    }
                                    selected={headerData[3]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal &&
                        cxGroupCountVal &&
                        cxGroupRegionVal && (
                            <Slide>
                                <BuildyourCXTeamStep5
                                    onSelect={(value) =>
                                        setValuetoState(value, 'language')
                                    }
                                    selected={headerData[4]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal &&
                        cxGroupCountVal &&
                        cxGroupRegionVal &&
                        cxGroupLangVal && (
                            <Slide>
                                <BuildyourCXTeamStep6
                                    onSelect={(value) =>
                                        setValuetoState(value, 'post-sending')
                                    }
                                    selected={headerData[5]}
                                />
                            </Slide>
                        )}
                    {type &&
                        type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal &&
                        cxGroupCountVal &&
                        cxGroupRegionVal &&
                        cxGroupLangVal &&
                        cxGroupPostVal && (
                            <Slide>
                                <BuildyourCXTeamStep7
                                    inValidEmail={inValidEmail}
                                    inValidCEmail={inValidCEmail}
                                    inValidPhone={inValidPhone}
                                    emailNotMatched={emailNotMatched}
                                    onSelect={(value, field) =>
                                        setValuetoState(value, 'about', field)
                                    }
                                    details={headerData[10]}
                                />
                            </Slide>
                        )}
                    {type === 'Contact Center Outsourcing' &&
                        cxGroupTeamVal &&
                        cxGroupCountVal &&
                        cxGroupRegionVal &&
                        cxGroupLangVal &&
                        cxGroupPostVal &&
                        cxGroupInfoVal && (
                            <Slide>
                                <BuildyourCXTeamStep8
                                    formSubmitted={formSubmitted}
                                    sliderRef={sliderRef}
                                    headerMenus={headerData}
                                    onClickAction={(val) =>
                                        handleFormAction(val)
                                    }
                                />
                            </Slide>
                        )}
                    {formSubmitted && (
                        <Slide>
                            <BuildyourCXTeamStep9
                                data={headerData}
                                onClickAction={(val) => handleFormAction(val)}
                            />
                        </Slide>
                    )}
                </SlickSlider>
            </SliderWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    background: ${colors.white['100']};
    z-index: 9999;
    top: 0;
    left: 0;
    ${(props) => {
        if (props.open) {
            return `opacity: 1;
                    visibility: visible;
                    transform: scale(1)`;
        } else {
            return `opacity: 0;
                    visibility: hidden;
                    transform: scale(0.8)`;
        }
    }};
`;
const SliderWrapper = styled.div`
    .slick-arrow {
        position: absolute;
        top: auto;
        bottom: 100px;
        z-index: 99;
        left: calc(50% - 190px);
        height: 45px;
        width: 190px;
        border-radius: 30px;
        background: ${colors.white['100']};
        &:before {
            background-size: 75px;
            ${Media('xlscreens')} {
                background-size: 4vw;
            }
        }
        ${Media('xlscreens')} {
            width: 10vw;
            height: 2.36vw;
            left: calc(50% - 10vw);
            bottom: 5.26vw;
        }
        ${Media('tabletlandscape')} {
            bottom: 10px;
            left: calc(50% - 190px) !important;
        }
        ${Media('tablet')} {
            position: fixed;
            left: calc(50% - 150px) !important;
            height: 40px;
            width: 140px;
        }
    }
    .slick-slide,
    .slick-slide * {
        outline: none !important;
    }
    .slick-prev {
        border: none;
        transition: all 0.5s ease-in-out;
        &:before {
            background-image: url(${baseURL}assets/images/back-arrow-cxform.png);
            transform: none !important;
        }
        ${Media('tablet')} {
            border: 1px solid ${colors.gray_dark['100']};
            &.slick-disabled {
                border: 1px solid ${colors.gray_light['100']};
            }
        }
        &.slick-disabled {
            &:hover {
                background-color: transparent;
                &:before {
                    background-image: url(${baseURL}assets/images/back-arrow-cxform.png);
                }
            }
        }
        &:hover {
            background-color: ${colors.black['100']};
            border: none;
            &:before {
                background-image: url(${baseURL}assets/images/back-arrow-white-cx.png);
            }
        }
    }
    .slick-next {
        transform: translateX(200px);
        border: 1px solid ${colors.gray_dark['100']};
        transition: all 0.5s ease-in-out;
        &:before {
            background-image: url(${baseURL}assets/images/next-arrow-cxform.png);
        }
        &.slick-disabled {
            &:hover {
                border: 1px solid ${colors.gray_light['100']};
                background-color: transparent;
                &:before {
                    background-image: url(${baseURL}assets/images/next-arrow-cxform.png);
                }
            }
            border: 1px solid ${colors.gray_light['100']};
        }
        ${Media('tablet')} {
            transform: translateX(150px);
        }
        &:hover {
            background-color: ${colors.pink['100']};
            border: none;
            &:before {
                background-image: url(${baseURL}assets/images/next-arrow-white-cx.png);
            }
        }
    }
`;
const Slide = styled.div`
    height: calc(100vh - 82px);
    width: 100%;
    ${Media('xlscreens')} {
        height: calc(100vh - 4.31vh);
    }
`;
export default BuildYourCX;
