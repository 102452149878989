import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const EbookFeatureImage = ({
    image,
    noMarginBottom,
    noMarginTop,
    wideImg,
}) => {
    return (
        <Wrapper
            noMarginBottom={noMarginBottom}
            noMarginTop={noMarginTop}
            wideImg={wideImg}
        >
            <img src={image} alt="-" />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: ${(props) => (props.wideImg ? '860px' : '500px')};
    height: ${(props) => (props.wideImg ? '780px' : '640px')};
    overflow: hidden;
    margin: ${(props) => (props.noMargin ? '0 auto' : '30px auto 60px')};
    margin-top: ${(props) => (props.noMarginTop ? '0' : '30px')};
    margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '60px')};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        width: ${(props) => (props.wideImg ? '44.79vw' : '29.47vw')};
        height: ${(props) => (props.wideImg ? '40.6vw' : '33.33vw')};
        margin-top: ${(props) => (props.noMarginTop ? '0' : '3vw')};
        margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '3vw')};
    }
    ${Media('tablet')} {
        width: 400px;
        height: 480px;
    }
    ${Media('mobile')} {
        width: 100%;
        height: auto;
    }
`;
