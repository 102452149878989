import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

const BuildYourCXInfo = ({ image }) => {
    return (
        <Wrapper>
            <ImageWrapper>
                <img src={image} alt="-" />
            </ImageWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    ${Media('xlscreens')} {
        padding: 1.05vw 0vw;
    }
    ${Media('tabletlandscape')} {
        padding: 0px 0px;
    }
`;
const ImageWrapper = styled.div`
    height: 300px;
    width: 60%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    ${Media('xlscreens')} {
        height: 15.7vw;
    }
    ${Media('tabletlandscape')} {
        width: 100%;
        height: 200px;
        img {
            border-radius: 0px;
        }
    }
`;

export default BuildYourCXInfo;
