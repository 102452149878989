import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroyRegular } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const Para = ({
    children,
    noMarginTop,
    color,
    lineHeight,
    regular,
    noMarginBottom,
}) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Wrapper
                noMarginTop={noMarginTop}
                color={color}
                regular={regular}
                noMarginBottom={noMarginBottom}
                lineHeight={lineHeight}>
                {children}
            </Wrapper>
        </ScrollAnimation>
    );
};

const Wrapper = styled.p`
    margin-top: 25px;
    margin-bottom: 50px;
    font-size: 24px;
    font-family: ${(props) => (props.regular ? gilroyRegular : gilroyMedium)};
    color: ${(props) =>
        props.color ? props.color : colors.cx_pocket_guide['dark_gray']};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.75)};
    ${(props) => {
        if (props.noMarginTop) {
            return `
                margin-top:0 !important;
            `;
        }
        if (props.noMarginBottom) {
            return `
                margin-bottom:0 !important;
            `;
        }
    }}
    ${Media('xlscreens')} {
        margin-top: 1.3vw;
        margin-bottom: 2.6vw;
        font-size: 1.25vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
