import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from './header';
import Footer from './footer';
import fetch from 'cross-fetch';
import styled from 'styled-components';

const Layout = ({
    children,
    page,
    noheaderfooter,
    nofooter,
    onlyLogoHeader,
    handleCXDemoForm,
    handleCXForm,
    searchBlogs,
    noSearchHeader,
    bgColor,
    darkTheme,
    npPaddingBottom,
}) => {
    const router = useLocation();

    const [seoData, setSeoData] = useState(null);

    const getSeoData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/import-seo?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        path: router.pathname,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                if (typeof resData.data !== undefined && resData.data != null) {
                    setSeoData(resData.data);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getSeoData();
    }, []);

    return (
        <Fragment>
            <Helmet>
                {page === 'digital_con' ? (
                    <script
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-M5XMQ3277F"></script>
                ) : null}
                {page === 'digital_con' ? (
                    <script async>{`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', 'G-M5XMQ3277F');
                        `}</script>
                ) : null}
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=UA-114654890-1"
                />
                <script async>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)}
                    gtag('js', new Date());
    
                    gtag('config', 'UA-114654890-1');
                `}</script>
                <script async>{`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KQQ2KWT');
                `}</script>
                {page === 'home' ? (
                    <script async>{`
                        (function(i,s,o,g,r,a,m){i['SLScoutObject']=r;i[r]=i[r]||function(){
                            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                            })(window,document,'script','https://scout-cdn.salesloft.com/sl.js','slscout');
                            slscout(["init", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMDE4Nzh9.WwYKP9c3RKDdsb6suuZGswtWVXhYhvxNe37O39e13ZE"]);
                    `}</script>
                ) : null}
                <meta
                    name="google-site-verification"
                    content="I-GEGeN1BCYm2MMeSL2_bFv-txE3zWzs1hxODnnq-e0"
                />
                {seoData != null && typeof seoData !== 'undefined' && (
                    <>
                        <title>{seoData.title}</title>
                        <meta
                            name="description"
                            content={seoData.description}
                        />
                        <link
                            rel="canonical"
                            href={
                                seoData.canonical != ''
                                    ? seoData.canonical
                                    : window.location.href
                            }
                        />
                        <meta
                            property="og:type"
                            content={
                                seoData.open_graph_type != ''
                                    ? seoData.open_graph_type
                                    : 'website'
                            }
                        />
                        <meta
                            property="og:title"
                            content={
                                seoData.open_graph_title != ''
                                    ? seoData.open_graph_title
                                    : seoData.title
                            }
                        />
                        <meta
                            property="og:description"
                            content={
                                seoData.open_graph_description != ''
                                    ? seoData.open_graph_description
                                    : seoData.description
                            }
                        />
                        <meta
                            property="og:image"
                            content={
                                Object.keys(seoData.open_graph_images).length >
                                0
                                    ? Object.keys(seoData.open_graph_images)[0]
                                    : process.env.REACT_APP_ROOT_URL +
                                      '/app/uploads/2021/03/main-og-image.jpg'
                            }
                        />
                        <meta
                            property="og:url"
                            content={
                                seoData.open_graph_url != ''
                                    ? seoData.open_graph_url
                                    : window.location.href
                            }
                        />
                        <meta
                            property="og:site_name"
                            content={
                                seoData.open_graph_site_name != ''
                                    ? seoData.open_graph_site_name
                                    : 'ibex.'
                            }
                        />
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            name="twitter:title"
                            content={
                                seoData.twitter_title != ''
                                    ? seoData.twitter_title
                                    : seoData.title
                            }
                        />
                        <meta
                            name="twitter:description"
                            content={
                                seoData.twitter_description != ''
                                    ? seoData.twitter_description
                                    : seoData.description
                            }
                        />
                        <meta
                            name="twitter:image"
                            content={
                                seoData.twitter_image != ''
                                    ? seoData.twitter_image
                                    : process.env.REACT_APP_ROOT_URL +
                                      '/app/uploads/2021/03/main-og-image.jpg'
                            }
                        />
                        <meta
                            name="twitter:site"
                            content={seoData.twitter_site}
                        />
                        <meta
                            name="twitter:creator"
                            content={seoData.twitter_creator}
                        />
                    </>
                )}
            </Helmet>
            <MainWrapper
                id="main"
                noheaderfooter={noheaderfooter}
                style={{
                    ...(noheaderfooter && { paddingTop: '0px' },
                    npPaddingBottom && { paddingBottom: '0px' }),
                    backgroundColor: bgColor,
                }}>
                {!noheaderfooter && (
                    <Header
                        page={page}
                        onlyLogoHeader={onlyLogoHeader}
                        handleCXDemoForm={(val) => handleCXDemoForm(val)}
                        handleCXForm={handleCXForm}
                        searchBlogs={(term_id) => searchBlogs(term_id)}
                        noSearchHeader={noSearchHeader}
                        darkTheme={darkTheme}
                    />
                )}
                {children}
            </MainWrapper>
            {!noheaderfooter && !nofooter ? (
                <Footer vPage={page} noFooterForm />
            ) : null}
        </Fragment>
    );
};

const MainWrapper = styled.div`
    ${props=>{
        if(props.noheaderfooter){
            return `
                padding-top:0px !important;
            `;
        }
    }}
`;

export default Layout;
