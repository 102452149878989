import React from 'react';
import styled from 'styled-components';
import { gilroyMedium } from '../theme/fonts';
import colors from '../theme/colors';
import Media from '../theme/medias';

const Button = ({
    children,
    ifButton,
    bgColor,
    color,
    borderColor,
    borderRadius,
    round,
    href,
    submit,
    onClick,
    size,
    target,
    rel,
    download,
}) => {
    return (
        <Main
            as={ifButton ? 'button' : 'a'}
            type={submit ? 'submit' : null}
            href={!ifButton ? href : null}
            round={round}
            bgColor={bgColor}
            color={color}
            borderColor={borderColor}
            onClick={onClick}
            size={size}
            borderRadius={borderRadius}
            target={target}
            rel={rel}
            download={download}>
            {children}
        </Main>
    );
};

const Main = styled.a`
    ${(props) => {
        if (props.borderRadius) {
            return `border-radius: ${props.borderRadius}`;
        } else if (props.round) {
            return `border-radius: 30px`;
        } else {
            return 'border-radius: 0';
        }
    }};
    font-size: 14px;
    font-family: ${gilroyMedium};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    padding: ${(props) => (props.size === 'small' ? '7px 15px' : '10px 20px')};
    min-width: ${(props) => (props.size === 'small' ? '1px' : '142px')};
    border: 1px solid;
    border-color: ${(props) =>
        props.borderColor ? props.borderColor : 'transparent'};
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;
    min-width: 142px;
    box-sizing: border-box;
    &:before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        z-index: -1;
        background-color: ${(props) =>
            props.bgColor && props.bgColor === 'transparent'
                ? props.color
                : colors.white['100']};
        border-radius: inherit;
        transform: scale(0.7);
        opacity: 0;
        transition: all 0.3s ease;
    }
    &:hover {
        color: ${(props) =>
            props.bgColor && props.bgColor === colors.white['100']
                ? colors.pink['100']
                : props.bgColor && props.bgColor !== 'transparent'
                ? props.bgColor
                : props.bgColor !== 'transparent'
                ? colors.pink['100']
                : colors.white['100']};
        &:before {
            transform: scale(1);
            opacity: 1;
        }
    }
    ${Media('xlscreens')} {
        padding: ${(props) =>
            props.size === 'small' ? '0.5vw 1.1vw' : '0.72vw 1.5vw'};
        font-size: ${(props) => (props.size === 'small' ? '0.73vw' : '0.8vw')};
        min-width: 9vw;
        &:before {
            top: 0.1vw;
            bottom: 0.1vw;
            left: 0.1vw;
            right: 0.1vw;
        }
    }
    ${Media('desktopscreens')} {
        padding: 0.72vw 1.5vw;
        font-size: 0.95vw;
    }
`;

export default Button;
