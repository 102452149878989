import React, { useState, useEffect } from 'react';
const MarketoForm = ({ Id, page }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const appendScript = (baseUrl, setScriptLoaded) => {
        if (window.MktoForms2) return setScriptLoaded(true);
        const script = document.createElement('script');
        script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;

        script.onload = () =>
            window.MktoForms2 ? setScriptLoaded(true) : null;
        document.body.appendChild(script);
    };

    const useMarketo = ({ baseUrl, munchkinId, formId }) => {
        useEffect(() => {
            if (scriptLoaded) {
                window.MktoForms2.loadForm(baseUrl, munchkinId, formId);
                window.MktoForms2.whenReady(function (form) {
                    form.onValidate(function () {
                        // config area
                        var invalidDomains = [
                                'hotmail.com',
                                'yahoo.com',
                                'aol.com',
                                'gmail.com',
                                'msn.com',
                                'comcast.net',
                            ],
                            emailExtendedValidationError =
                                'Please provide a corporate email address!',
                            emailField = 'Email',
                            emailFieldSelector = '#' + emailField;

                        // /@(example.com|example2.com)$/i
                        var invalidDomainRE = new RegExp(
                            '@(' + invalidDomains.join('|') + ')$',
                            'i'
                        );

                        if (invalidDomainRE.test(form.vals()[emailField])) {
                            form.showErrorMessage(
                                emailExtendedValidationError,
                                form.getFormElem().find(emailFieldSelector)
                            );
                            form.submittable(false);
                        } else {
                            form.submittable(true);
                        }
                    });
                    {
                        page &&
                            page === 'template' &&
                            form.onSuccess(function (vals, thankYouURL) {
                                var formEl = form.getFormElem()[0],
                                    thankYouLoc = document.createElement('A'),
                                    thankYouContainer =
                                        formEl.querySelector(
                                            '#thankYouContent'
                                        ) || document.createElement('DIV'),
                                    thankYouHTML;
                                thankYouContainer.setAttribute('id','thankYouContent')
                                thankYouHTML = decodeURIComponent(
                                    ((thankYouLoc.href = thankYouURL),
                                    thankYouLoc).hash.substring(1)
                                );
                                formEl.innerHTML =
                                    ((thankYouContainer.innerHTML =
                                        thankYouHTML),
                                    thankYouContainer).outerHTML;

                                return false;
                            });
                    }
                });
                return;
            }
            appendScript(baseUrl, setScriptLoaded);
        }, [scriptLoaded, baseUrl, munchkinId, formId]);
    };

    useMarketo({
        baseUrl: '//go.ibex.co',
        munchkinId: '266-QTU-342',
        formId: Id,
    });

    return <form id={`mktoForm_${Id}`} />;
};
export default MarketoForm;
