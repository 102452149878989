import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { Col, Row } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

import { Para } from '../cx-pocket-guide/para';
import List from './list';
import { BlockImg } from '../imperative-cx/block-img';

export const Point3 = () => {
    return (
        <Wrapper>
            <Row>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-3.svg`}
                    />
                </Col>
                <Col lg="6">
                    <TextBlock>
                        <Para regular noMarginTop>
                            Value Stream Mapping is a method to analyze, manage,
                            design, and visualize the flow of information and
                            processes needed to deliver a service, product, or
                            experience to a customer. The goal is to identify
                            areas of waste and variation that can be improved or
                            removed entirely to improve efficiency while
                            maintaining an exceptional customer experience.
                        </Para>
                        <Para regular noMarginBottom>
                            Your business intelligence team will:
                        </Para>
                    </TextBlock>
                </Col>
            </Row>
            <div style={{ marginTop: 50 }}>
                <List>
                    <li>
                        Analyze customer data to map key processes and inputs
                        as&nbsp;
                        <b>“adding value”</b> or <b>“not adding value”</b> from
                        the customers’ point of view.
                    </li>
                    <li>
                        Streamline processes by identifying where to eliminate
                        things that are not adding any value — or might actually
                        be creating more friction in the customer experience.
                    </li>
                    <li>
                        Making strategic recommendations to processes and&nbsp;
                        <b>tech-driven solutions</b> to drive positive change.
                    </li>
                </List>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const TextBlock = styled.div`
    padding-left: 90px;
    ${Media('xlscreens')} {
        padding-left: 4.68vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
        margin-top: 30px;
    }
`;
