import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import { FaSearch } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import languages from '../languages';
import Select from 'react-select';
import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const Step5 = ({ onSelect, selected }) => {
    const selector = useRef(null);
    const [hideSearchBar, setHideSearchBar] = useState(false);
    const customSelectorStyle = {
        control: () => ({
            border: 'none',
        }),
        indicatorsContainer: () => ({
            display: 'none',
        }),
        valueContainer: (base) => ({
            ...base,
            fontSize: '15px',
            top: '0',
            marginLeft: '4px',
            overflow: 'visible',
            minHeight: '50px',
            cursor: 'pointer',
        }),
        placeholder: (base) => ({
            ...base,
            fontStyle: 'italic',
            position: 'absolute',
        }),
        multiValue: () => ({
            display: 'none',
        }),
    };
    const handleSelector = (value) => {
        if (value) {
            onSelect(value);
        } else {
            onSelect([]);
        }
    };
    useEffect(() => {
        selector.current.state.value = selected.value;
    }, []);
    const handleRemove = (label) => {
        const lang = selected.value;
        const n = lang.filter((la) => la.label !== label);
        selector.current.state.value = n;
        onSelect(n);
    };
    return (
        <Wrapper>
            <BuildYourCXInfo2 image={`${baseURL}assets/images/language.jpg`} />
            <SubHeading content="What languages will you require?" />
            <Row>
                <CenterAligned>
                    <Col lg="4">
                        <LanguageSearch>
                            {!hideSearchBar && (
                                <IconWrapper>
                                    <FaSearch />
                                </IconWrapper>
                            )}
                            {!hideSearchBar && (
                                <TextWrapper>Enter Your Language</TextWrapper>
                            )}
                        </LanguageSearch>
                        <Select
                            isMulti
                            name="colors"
                            options={languages}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            ref={selector}
                            styles={customSelectorStyle}
                            onChange={handleSelector}
                            placeholder=""
                            onMenuOpen={() => setHideSearchBar(true)}
                            onMenuClose={() => setHideSearchBar(false)}
                        />
                    </Col>
                </CenterAligned>
            </Row>
            <Row>
                <CenterAligned2>
                    {selected &&
                        selected.value &&
                        selected.value.map((language, index) => {
                            return (
                                <LanguagePill key={index}>
                                    <div>
                                        {language.code.toUpperCase()} &nbsp; |
                                        &nbsp; {language.label}
                                    </div>
                                    <CloseIcon
                                        onClick={() =>
                                            handleRemove(language.label)
                                        }>
                                        <MdClose />
                                    </CloseIcon>
                                </LanguagePill>
                            );
                        })}
                </CenterAligned2>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 10%;
    }
`;
const CenterAligned2 = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 50px 30px;
    flex-wrap: wrap;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 10%;
        max-height: calc(100vh - 550px);
        overflow: auto;
        margin-top: 20px;
    }
    ${Media('xlscreens')} {
        padding: 2.63vw 1.57vw;
    }
`;
const LanguageSearch = styled.div`
    width: 100%;
    border-bottom: 2px solid ${colors.gray['border']};
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    align-items: center;
    position: absolute;
    min-height: 50px;
    ${Media('xlscreens')} {
        padding: 1.05vw 0vw;
    }
    ${Media('tabletlandscape')} {
        left: 0;
    }
`;
const IconWrapper = styled.div`
    font-size: 25px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        font-size: 1.31vw;
        padding-right: 1.05vw;
    }
`;
const TextWrapper = styled.div`
    font-size: 13px;
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        font-size: 0.68vw;
    }
`;
const LanguagePill = styled.div`
    height: 40px;
    min-width: 8%;
    border-radius: 30px;
    margin: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: ${gilroyMedium};
    background-color: ${colors.pink['100']};
    color: ${colors.white['100']};
    padding: 0px 20px;
    ${Media('xlscreens')} {
        height: 2.1vw;
        border-radius: 1.57vw;
        margin: 0.26vw 0.26vw;
        font-size: 0.78vw;
        padding: 0vw 1.05vw;
    }
    ${Media('tabletlandscape')} {
        width: 100%;
        margin: 10px 0px;
        height: 50px;
    }
`;
const CloseIcon = styled.div`
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    ${Media('xlscreens')} {
        font-size: 1vw;
        padding-left: 1.05vw;
    }
`;
export default Step5;
