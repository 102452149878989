import styled from 'styled-components';
import Row from '@bootstrap-styled/v4/lib/Row';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';

export const RowCentered = styled(Row)`
    align-items: center;
`;

export const RowVEnd = styled(Row)`
    align-items: flex-end;
`;

export const RowCenteredBoth = styled(Row)`
    align-items: center;
    justify-content: center;
`;

export const RowReversedH = styled(Row)`
    flex-direction: row-reverse;
`;
export const RowContentCenter = styled(Row)`
    justify-content: center;
`;
export const RowReversedHCentered = styled(RowCentered)`
    flex-direction: row-reverse;
`;

export const RowContentBetween = styled(Row)`
    justify-content: space-between;
`;

export const FlexCentered = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FlexColumnCentered = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
export const MarketoFormDesign = styled.div`
    .mktoForm {
        width: 100% !important;
        ${(props) => props.halfField && `display: flex; flex-wrap: wrap`};
        .mktoOffset,
        .mktoGutter,
        .mktoRequiredField .mktoAsterix {
            display: none !important;
        }
        .mktoFormRow {
            width: ${(props) =>
                props.halfField
                    ? 'calc(50% - 15px) !important'
                    : '100% !important'};
            ${(props) => props.halfField && `padding-right: 15px;`};
            ${Media('mobile')} {
                width: 100% !important;
                padding: 0;
            }
        }
        .mktoFieldWrap,
        .mktoFormCol {
            width: 100% !important;
        }
        .mktoFormCol {
            margin-bottom: 20px !important;
            ${Media('xlscreens')} {
                margin-bottom: 1.05vw !important;
            }
        }
        .mktoLabel {
            width: auto !important;
            font-size: 18px !important;
            line-height: 24px !important;
            color: ${(props) =>
                props.darkBack
                    ? colors.white['100']
                    : colors.gray_dark['100']} !important;
            padding-bottom: 0.2em !important;
            font-weight: 300 !important;
            padding-top: 0 !important;
            ${Media('xlscreens')} {
                font-size: 0.95vw !important;
            }
        }
        input[type='text'],
        input[type='url'],
        input[type='email'],
        input[type='tel'],
        input[type='number'],
        input[type='date'],
        textarea.mktoField,
        select.mktoField {
            color: ${colors.gray_dark['100']};
            width: 100% !important;
            font-size: 20px;
            height: 48px !important;
            padding: 12px !important;
            outline: none !important;
            border: none !important;
            border-radius: 5px !important;
            background-color: ${colors.gray_lightest['100']} !important;
            ${Media('xlscreens')} {
                font-size: 1.05vw !important;
                height: 2.5vw !important;
                padding-top: 0.4vw !important;
            }
        }
        textarea.mktoField {
            height: 120px !important;
            ${Media('xlscreens')} {
                height: 4.88vw !important;
            }
        }
        .mktoButtonRow {
            display: block !important;
            text-align: center !important;
            flex-grow: 1;
            width: 100%;
            .mktoButtonWrap {
                margin-top: 20px !important;
                margin-left: 0px !important;
                padding: 0 8px !important;
                display: block !important;
                text-align: center !important;
                button.mktoButton {
                    position: relative !important;
                    left: 0 !important;
                    margin-top: 0 !important;
                    background: ${colors.pink['100']} !important;
                    border: 2px solid transparent !important;
                    color: ${colors.white['100']};
                    min-width: 220px !important;
                    border-radius: 30px !important;
                    padding: 12px 38px !important;
                    height: 48px !important;
                    font-size: 20px !important;
                    width: auto;
                    box-shadow: none;
                    font-weight: 400;
                    text-shadow: none;
                    text-transform: uppercase;
                    transition: all 330ms ease;
                    &:hover {
                        border-color: ${colors.pink['100']} !important;
                        background: transparent !important;
                        color: ${colors.pink['100']} !important;
                    }
                }
                ${Media('xlscreens')} {
                    margin-top: 1.05vw !important;
                    button.mktoButton {
                        font-size: 1.05vw !important;
                        height: 2.5vw !important;
                        padding: 0.2vw 2vw !important;
                    }
                }
                ${Media('tablet')} {
                    button.mktoButton {
                        white-space: normal !important;
                    }
                }
                ${Media('mobile')} {
                    button.mktoButton {
                        font-size: 16px !important;
                        height: auto !important;
                    }
                }
            }
        }
        .mktoTemplateBox {
            text-align: center;
            font-size: 16px !important;
            .mktoButtonWrap.mktoDownloadButton {
                button.mktoButton {
                    position: static !important;
                    background: ${colors.pink['100']} !important;
                    border: 2px solid transparent !important;
                    color: ${colors.white['100']};
                    min-width: 220px !important;
                    border-radius: 30px !important;
                    padding: 12px 38px !important;
                    height: 48px !important;
                    font-size: 20px !important;
                    width: auto;
                    box-shadow: none;
                    font-weight: 400;
                    text-shadow: none;
                    text-transform: uppercase;
                    transition: all 330ms ease;
                    &:hover {
                        border-color: ${colors.pink['100']} !important;
                        background: transparent !important;
                        color: ${colors.pink['100']} !important;
                    }
                }
            }
            ${Media('xlscreens')} {
                font-size: 1.05vw !important;
                .mktoButtonWrap.mktoDownloadButton {
                    button.mktoButton {
                        font-size: 1.05vw !important;
                        height: 2.5vw !important;
                        padding: 0.6vw 2vw !important;
                    }
                }
            }
        }
    }
`;
