import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyRegular } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

const List = ({ children }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Wrapper>{children}</Wrapper>
        </ScrollAnimation>
    );
};

const Wrapper = styled.ul`
    font-family: ${gilroyRegular};
    padding-left: 0px;
    li {
        list-style: inside;
        font-size: 24px;
        border-bottom: 1px solid ${colors.black['15']};
        padding: 48px 0;
        line-height: 1.66;
        color: ${colors.cx_pocket_guide['dark_gray']};
        span {
            font-family: ${gilroyBold};
            background-color: ${colors.pink['15']};
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
    ${Media('xlscreens')} {
        li {
            font-size: 1.25vw;
            padding: 2.5vw 0;
        }
    }
    ${Media('tablet')} {
        li {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        li {
            font-size: 14px;
        }
    }
`;

export default List;
