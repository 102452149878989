import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import { FaSearch, FaAd } from 'react-icons/fa';
import { IoIosGitNetwork } from 'react-icons/io';
import { MdPeople } from 'react-icons/md';

import BuildYourCXInfo from '../buildyourCX-info/buildyourCX-info';
import BuildYourCXOption from '../buildyourCX-option/buildyourCX-option';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import Media from 'shared-components/theme/medias';

const Step1 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <BuildYourCXInfo
                heading={
                    <Fragment>
                        Digital Marketing<span>.</span>
                    </Fragment>
                }
                para={
                    <Fragment>
                        Our Digital Marketing team can help you launch & manage
                        online ad campaigns across paid
                        <br />
                        search, social, display, and affiliate channels, with
                        the ability to connect this data directly into
                        <br />
                        the contact center, allowing for online-to-offline
                        customer conversion and end-to-end
                        <br />
                        conversion attribution.
                    </Fragment>
                }
            />
            <SubHeading content="What type of advertising do you run today?" />
            <Row>
                <CenterAligned>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label={<Fragment>Search</Fragment>}
                            Icon={FaSearch}
                            value="Search"
                            onSelectOption={(value) => onSelect(value)}
                            active={selected}
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label={<Fragment>Social</Fragment>}
                            Icon={IoIosGitNetwork}
                            value="Social"
                            onSelectOption={(value) => onSelect(value)}
                            active={selected}
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label={<Fragment>Display</Fragment>}
                            Icon={FaAd}
                            value="Display"
                            onSelectOption={(value) => onSelect(value)}
                            active={selected}
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label={<Fragment>Affiliate</Fragment>}
                            Icon={MdPeople}
                            value="Affiliate"
                            onSelectOption={(value) => onSelect(value)}
                            active={selected}
                        />
                    </Col>
                </CenterAligned>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 30px;
        max-height: calc(100vh - 480px);
        overflow: auto;
    }
`;
export default Step1;
