import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import { FaHeadphones, FaBullhorn } from 'react-icons/fa';

import BuildYourCXInfo from '../buildyourCX-info/buildyourCX-info';
import BuildYourCXOption from '../buildyourCX-option/buildyourCX-option';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ContactForm from '../../contact-form/contact-form';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const Step1 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <Row>
                <QuickConnectForm>
                    <FormWrapper>
                        <ContactForm
                            heading={
                                <Fragment>
                                    Quick Connect<span>.</span>
                                </Fragment>
                            }
                            color={`${colors.white['100']}`}
                            headingSize={'2.42vw'}
                            subHeading={
                                <Fragment>
                                    <span>
                                        <b>Busy day?</b> Give us your
                                        <br />
                                        business details and we’ll get
                                        <br />
                                        back to you asap.
                                    </span>
                                </Fragment>
                            }
                        />
                    </FormWrapper>
                </QuickConnectForm>
                <BuildYourCXWrapper>
                    <BuildYourCXInfo
                        heading={
                            <Fragment>
                                Build your CX team<span>.</span>
                            </Fragment>
                        }
                        para={
                            <Fragment>
                                We expertly manage&nbsp;
                                <span>138 Million interactions each year</span>
                                &nbsp; for our clients, and our engagement
                                <br />
                                model is built to ensure that each one is
                                expertly delivered.
                                <br />
                                <br />
                                <br />
                                To get started on building your program, we’ll
                                ask you some initial questions, and then one of
                                our <br />
                                senior team members will reach out directly.
                            </Fragment>
                        }
                    />
                    <SubHeading content="What type of team are you looking to build?" />
                    <ChooseYourTeam>
                        <span>Choose Your Team</span>
                        <img
                            src={`${baseURL}assets/images/dropdown-icon-pink.svg`}
                            alt="Drop down red icon"
                        />
                    </ChooseYourTeam>
                    <Row>
                        <CenterAligned>
                            <Col lg="3" xs="12" md="12">
                                <BuildYourCXOption
                                    label={
                                        <Fragment>
                                            Contact Center <br /> Outsourcing
                                        </Fragment>
                                    }
                                    Icon={FaHeadphones}
                                    value="Contact Center Outsourcing"
                                    onSelectOption={(value) => onSelect(value)}
                                    active={selected}
                                />
                            </Col>
                            <Col lg="3" xs="12" md="12">
                                <BuildYourCXOption
                                    label={
                                        <Fragment>
                                            Digital <br /> Marketing
                                        </Fragment>
                                    }
                                    Icon={FaBullhorn}
                                    value="Digital Marketing"
                                    onSelectOption={(value) => onSelect(value)}
                                    active={selected}
                                />
                            </Col>
                        </CenterAligned>
                    </Row>
                </BuildYourCXWrapper>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    ${Media('tabletlandscape')} {
        max-height: calc(70vh);
        overflow: auto;
    }
`;
const QuickConnectForm = styled.div`
    display: flex;
    justify-content: center;
    height: calc(100vh - 82px);
    padding-top: 42px;
    background: ${colors.black['100']};
    width: 35%;
    ${Media('xlscreens')} {
        padding-top: 2.21vw;
    }
    ${Media('tabletlandscape')} {
        height: calc(100vh);
        padding-top: 20px;
        width: 100%;
    }
`;
const FormWrapper = styled.div`
    width: 70%;
    height: 100%;
    ${Media('tabletlandscape')} {
        height: calc(80vh);
        overflow: auto;
    }
`;
const BuildYourCXWrapper = styled.div`
    width: 65%;
    ${Media('tabletlandscape')} {
        width: 100%;
    }
`;
const ChooseYourTeam = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    span {
        font-size: 13px;
        font-family: ${gilroyMedium};
        padding-bottom: 10px;
    }
    svg {
        height: 10px;
        width: 10px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.1vw;
    }
    ${Media('tabletlandscape')} {
        padding: 10px 0px;
        margin-bottom: 0px;
    }
`;
const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 30px;
        max-height: calc(100vh - 490px);
        overflow: auto;
    }
`;
export default Step1;
